<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            :title="`Crear Lote - Granja: ${nombreGranja}`"
            :sub-title="'Ingreso de datos para lote'"
          >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Fecha de Ingreso"
                      rules="required"
                    >
                      <base-input
                        v-model="datosLote.fecha_ingreso"
                        type="date"
                        label="Fecha de Ingreso"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Cantidad"
                      rules="required|numeric|min_value:1"
                    >
                      <base-input
                        v-model="datosLote.cantidad"
                        type="number"
                        label="Cantidad"
                        placeholder="Cantidad"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Edad Despique"
                      rules="required|numeric|min_value:1"
                    >
                      <label>
                        Edad Despique
                        <i
                          class="fas fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Ingrese la edad en semanas"
                          style="margin-left: 5px; cursor: pointer"
                        ></i>
                      </label>
                      <base-input
                        v-model="datosLote.edad_despique"
                        type="number"
                        placeholder="Edad Despique"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Estirpe"
                      rules="required|not_selected"
                    >
                      <base-input label="Estirpe">
                        <select
                          v-model="datosLote.estirpe_id"
                          class="custom-select"
                        >
                          <option value="-1">(Seleccionar Estirpe)</option>
                          <option
                            v-for="estirpe in estirpes"
                            :key="estirpe.id"
                            :value="estirpe.id"
                          >
                            {{ estirpe.estirpe }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Galpon"
                      rules="required|not_selected"
                    >
                      <base-input label="Galpon a alojar">
                        <select
                          v-model="datosLote.galpon_id"
                          class="custom-select"
                        >
                          <option value="-1">(Seleccionar Galpon)</option>
                          <option
                            v-for="galpon in galpones"
                            :key="galpon.id"
                            :value="galpon.id"
                          >
                            Galpon No.{{ galpon.numero }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4" style="margin: 12px">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Edad Producción"
                      rules="required|numeric|min_value:1"
                    >
                      <label>
                        Edad Producción
                        <i
                          class="fas fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Ingrese la edad en semanas"
                          style="margin-left: 5px; cursor: pointer"
                        ></i>
                      </label>
                      <base-input
                        v-model="datosLote.edad_produccion"
                        type="number"
                        placeholder="Edad Producción"
                        disabled
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import Swal from "sweetalert2";
import { mapState } from "pinia";
import { useAuthUserStore } from "../stores/auth.store";

// Extend vee-validate rules
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// Custom validation rule
extend("not_selected", {
  validate: (value) => value !== "-1",
  message: "Debe seleccionar una opción válida.",
});

// Set locale to Spanish
localize("es", es);

export default {
  name: "CrearLote",
  components: { Card, ValidationProvider, ValidationObserver },
  props: {
    selectedGalpon: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      datosLote: {
        fecha_ingreso: "",
        cantidad: undefined,
        edad_despique: undefined,
        estirpe_id: -1,
        galpon_id: -1,
        edad_produccion: 18,
      },
      estirpes: [],
      galpones: [],
      loading: false,
    };
  },

  computed: {
    ...mapState(useAuthUserStore, {
      user: (state) => state.user,
    }),
    nombreGranja() {
      return this.user?.granja?.nombre || "";
    },
  },

  mounted() {
    this.cargarEstirpes();
    this.cargarGalpones();
  },
  methods: {
    async cargarEstirpes() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/estirpe");
        this.estirpes = data;
      } catch (error) {
        console.error("Error al cargar estirpes:", error);
        alert(
          "No se pudieron cargar los estirpes. Por favor, intenta nuevamente.",
        );
      } finally {
        this.loading = false;
      }
    },
    async cargarGalpones() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/galpon/listarUsuarioGalpones");
        this.galpones = data;
        if (this.galpones.length === 0) {
          this.mostrarAdvertencia();
        }
      } catch (error) {
        console.error("Error al cargar galpones:", error);
        alert(
          "No se pudieron cargar los galpones. Por favor, intenta nuevamente.",
        );
      } finally {
        this.loading = false;
      }
    },

    mostrarAdvertencia() {
      Swal.fire({
        title: "Aún no existen granjas para gestionar lotes",
        text: "¿Desea crear una granja en este momento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "CrearGranja" });
        } else {
          this.$router.push({ name: "Overview" });
        }
      });
    },
    async guardar() {
      this.loading = true;
      try {
        const data = {
          fecha_ingreso: this.datosLote.fecha_ingreso,
          cantidad: this.datosLote.cantidad,
          edad_despique: this.datosLote.edad_despique,
          estirpe_id: this.datosLote.estirpe_id,
          galpon_id: this.datosLote.galpon_id,
          edad_produccion: this.datosLote.edad_produccion,
        };
        await axios.post("/api/lote", data);
        this.$router.push({ name: "Lote" });
      } catch (error) {
        console.error("Error al guardar los datos:", error);
        alert(
          "No se pudieron guardar los datos. Por favor, intenta nuevamente.",
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}
</style>
