<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">Lista de Lotes</h4>
              <div class="d-flex justify-content-between">
                <div>
                  <button
                    class="btn btn-primary btn-fill mr-2"
                    @click="handleCrear"
                  >
                    Crear Lote
                  </button>
                </div>
              </div>
            </template>
            <div
              v-if="lotes.length"
              class="table-hover table-striped table-bordered"
            >
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="text-center">N°</th>
                    <th class="text-center">Fecha Ingreso</th>
                    <th class="text-center">Cantidad</th>
                    <th class="text-center">Edad Despique</th>
                    <th class="text-center">Estirpe</th>
                    <th class="text-center">Edad Producción</th>
                    <th class="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lote in lotes" :key="lote.id">
                    <td>{{ lote.id }}</td>
                    <td>{{ lote.fecha_ingreso }}</td>
                    <td>{{ lote.cantidad }}</td>
                    <td>Semana {{ lote.edad_despique }}</td>
                    <td>{{ lote.estirpe.estirpe }}</td>
                    <td>Semana {{ lote.edad_produccion }}</td>
                    <td>
                      {{ lote.activo ? "Activo" : "Inactivo" }}
                      ({{ getLoteEstado(lote.estado) }})
                    </td>

                    <td class="text-center">
                      <button
                        class="btn btn-primary btn-sm mr-2"
                        @click="verLote(lote.id)"
                      >
                        Ver
                      </button>
                      <button
                        class="btn btn-warning btn-sm"
                        @click="editarLote(lote.id)"
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="clearfix" />
            </div>
            <div v-else class="text-center">
              <p>No hay lotes disponibles.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      lotes: [],
    };
  },
  mounted() {
    this.cargarLotes();
  },
  methods: {
    getLoteEstado(estado) {
      const estados = {
        NUEVO: "Nuevo",
        EN_PROCESO: "En Proceso",
        PROCESADO: "Procesado",
      };
      return estados[estado] || estado;
    },

    async cargarLotes() {
      try {
        const { data } = await axios.get("/api/lote/lotes");
        this.lotes = data;
      } catch (error) {
        console.error("Hubo un error al obtener los lotes:", error);
      }
    },
    handleCrear() {
      this.$router.push({
        name: "CrearLote",
      });
    },
    verLote(loteId) {
      this.$router.push({
        name: "DetalleLote",
        params: { id: loteId },
      });
    },

    editarLote(loteId) {
      this.$router.push({
        name: "EditarLote",
        params: { id: loteId },
      });
    },
  },
};
</script>
