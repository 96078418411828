<template>
  <div v-if="lote" class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">Detalles del Lote</h4>
            </template>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Fecha de Ingreso</th>
                    <td>{{ lote.fecha_ingreso }}</td>
                  </tr>
                  <tr>
                    <th>Cantidad</th>
                    <td>{{ lote.cantidad }}</td>
                  </tr>
                  <tr>
                    <th>Edad Despique</th>
                    <td>Semana {{ lote.edad_despique }}</td>
                  </tr>
                  <tr>
                    <th>Estirpe</th>
                    <td>{{ lote.estirpe.estirpe }}</td>
                  </tr>
                  <tr>
                    <th>Edad Producción</th>
                    <td>Semana {{ lote.edad_produccion }}</td>
                  </tr>
                  <tr>
                    <th>Estado</th>
                    <td>
                      {{ lote.activo ? "Activo" : "Inactivo" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <p>Cargando detalles del lote...</p>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      lote: null,
    };
  },
  mounted() {
    this.cargarLote();
  },
  methods: {
    async cargarLote() {
      try {
        const { data } = await axios.get(
          `/api/lote/lotes/${this.$route.params.id}`,
        );
        this.lote = data;
      } catch (error) {
        console.error("Hubo un error al obtener el lote:", error);
      }
    },
  },
};
</script>
